.loader {
  width: 75px;
  height: 75px;
  margin: 1rem auto;
  -webkit-animation: fadeinout 2s infinite;
  animation: fadeinout 2s infinite;
}
.text-truncate {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
